.discord-icon {
  width: 40px;
}

.twitter-icon {
  width: 40px;
}

i {
  font-size: 30px;
}

footer {
  background-color: #373c3e;
}
