.navbar a {
  color: black !important;
  font-size: 30px !important;
}

ul li a {
  margin-left: 40px !important;
  text-shadow: 2px 2px #f7f2f2;
}

@media (max-width: 1000px) {
  ul li a {
    margin-left: 0px !important;
  }
}
