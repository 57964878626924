@import url("https://fonts.googleapis.com/css2?family=Holtwood+One+SC&display=swap");
@import url("https://fonts.googleapis.com/css2?family=ABeeZee&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  margin-top: 0px;
  height: 100%;
  font-family: "Holtwood One SC", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5c622;
  color: white;
}
