.custom-shape-divider-bottom-1641775128 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1641775128 svg {
  position: relative;
  display: block;
  width: calc(146% + 1.3px);
  height: 88px;
}

.custom-shape-divider-bottom-1641775128 .shape-fill {
  fill: #373c3e;
}
