h1 {
  font-family: "Holtwood One SC", serif;
  font-weight: 800;
  font-size: 2.5rem;
  color: white;
  text-transform: uppercase;
}
.accordion-section .panel-default > .panel-heading {
  border: 0;
  background: #f4f4f4;
  padding: 0;
  border-radius: 10px;
}
.accordion-section .panel-default .panel-title a {
  display: block;
  font-style: italic;
  font-size: 1.5rem;
}
.accordion-section .panel-default .panel-title a:after {
  font-family: "FontAwesome";
  font-style: normal;
  font-size: 3rem;
  content: "\f106";
  color: #0d0d0e;
  float: right;
  margin-top: -12px;
}
.accordion-section .panel-default .panel-title a.collapsed:after {
  content: "\f107";
}
.accordion-section .panel-default .panel-body {
  font-size: 1.2rem;
  font-family: "ABeeZee", sans-serif;
}

a {
  color: #091f2f;
  text-decoration: none !important;
}

@media (min-width: 1000px) {
  .tittle {
    font-size: 5rem;
  }
}
