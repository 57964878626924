@import url(https://fonts.googleapis.com/css2?family=Holtwood+One+SC&display=swap);
@import url(https://fonts.googleapis.com/css2?family=ABeeZee&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Holtwood+One+SC&display=swap);
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  margin-top: 0px;
  height: 100%;
  font-family: "Holtwood One SC", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5c622;
  color: white;
}

h1 {
  font-family: "Holtwood One SC", serif;
  font-weight: 800;
  font-size: 2.5rem;
  color: white;
  text-transform: uppercase;
}
.accordion-section .panel-default > .panel-heading {
  border: 0;
  background: #f4f4f4;
  padding: 0;
  border-radius: 10px;
}
.accordion-section .panel-default .panel-title a {
  display: block;
  font-style: italic;
  font-size: 1.5rem;
}
.accordion-section .panel-default .panel-title a:after {
  font-family: "FontAwesome";
  font-style: normal;
  font-size: 3rem;
  content: "\f106";
  color: #0d0d0e;
  float: right;
  margin-top: -12px;
}
.accordion-section .panel-default .panel-title a.collapsed:after {
  content: "\f107";
}
.accordion-section .panel-default .panel-body {
  font-size: 1.2rem;
  font-family: "ABeeZee", sans-serif;
}

a {
  color: #091f2f;
  text-decoration: none !important;
}

@media (min-width: 1000px) {
  .tittle {
    font-size: 5rem;
  }
}

.discord-icon {
  width: 40px;
}

.twitter-icon {
  width: 40px;
}

i {
  font-size: 30px;
}

footer {
  background-color: #373c3e;
}

.navbar a {
  color: black !important;
  font-size: 30px !important;
}

ul li a {
  margin-left: 40px !important;
  text-shadow: 2px 2px #f7f2f2;
}

@media (max-width: 1000px) {
  ul li a {
    margin-left: 0px !important;
  }
}

.roadmap {
  color: #131212;
}

ul.timeline {
  list-style-type: none;
  position: relative;
  padding-left: 1.5rem;
}

/* Timeline vertical line */
ul.timeline:before {
  content: " ";
  background: #fff;
  display: inline-block;
  position: absolute;
  left: 16px;
  width: 4px;
  height: 100%;
  z-index: 400;
  border-radius: 1rem;
}

li.timeline-item {
  margin: 20px 0;
}

/* Timeline item arrow */
.timeline-arrow {
  border-top: 0.5rem solid transparent;
  border-right: 0.5rem solid #fff;
  border-bottom: 0.5rem solid transparent;
  display: block;
  position: absolute;
  left: 2rem;
}

/* Timeline item circle marker */
li.timeline-item::before {
  content: " ";
  background: #ddd;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #fff;
  left: 11px;
  width: 14px;
  height: 14px;
  z-index: 400;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.text-small {
  margin-bottom: 0 !important;
  font-family: "ABeeZee", sans-serif;
}

.t-shadow {
  text-shadow: 3px 3px #131212;
}

ul {
  padding: 0px;
}

.icon {
  font-size: 10px;
}

#story {
  background-color: #373c3e;
  min-height: 100vh;
}
.tech-slideshow {
  height: 400px;
  width: 60%;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.tech-slideshow > div {
  height: 400px;
  width: 4000px;
  background: url(/static/media/slide3.d7ecfcd2.png);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transform: translate3d(0, 0, 0);
}
.tech-slideshow .mover-1 {
  animation: moveSlideshow 12s linear infinite;
}

.tech-slideshow .mover-2 {
  opacity: 0;
  transition: opacity 0.5s ease-out;
  background-position: 0 -200px;
  animation: moveSlideshow 15s linear infinite;
}
.tech-slideshow:hover .mover-2 {
  opacity: 1;
}

.card {
  color: black;
}

.card-body {
  font-family: "ABeeZee", sans-serif;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
}

@keyframes moveSlideshow {
  100% {
    transform: translateX(-66.6666%);
  }
}

@media (max-width: 700px) {
  .tech-slideshow {
    width: 100%;
  }
}

.slider {
  /* position: absolute; */
  width: 100%;
  /* top: calc(50% - 97.25px); */
  overflow: hidden;
}
.slider .slider-row {
  width: 2956px;
  height: 400px;
  background-image: url(/static/media/slide3.d7ecfcd2.png);
  background-size: 2956px 400px;
  animation: slide 50s linear infinite;
  margin: 50px 0px;
}

@keyframes slide {
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: -2956px;
  }
}

@media (min-width: 1000px) {
  .tittle {
    font-size: 5rem;
  }
}

.masthead {
  height: 100vh;
  /* background-image: url("../../assets/image/banner.png"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.desc {
  text-align: justify;
  color: black;
}

.img {
  border-radius: 10px;
}

.custom-shape-divider-bottom-1641684795 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1641684795 svg {
  position: relative;
  display: block;
  width: calc(124% + 1.3px);
  height: 188px;
}

.custom-shape-divider-bottom-1641684795 .shape-fill {
  fill: #373c3e;
}

@media (min-width: 1000px) {
  .welcome {
    font-size: 3rem;
  }
  .tittle {
    font-size: 5rem;
  }
}

@media (max-width: 1000px) {
  .welcome {
    font-size: 2rem;
  }
  .title-nft {
    font-size: 40px !important;
  }
}

.App {
  text-align: center;
}

.members {
  font-family: "ABeeZee", sans-serif;
  color: #8d97ad;
  font-weight: 300;
}

.members h6 {
  font-weight: bold;
}
.members .font-weight-medium {
  font-weight: 500;
}

.members .bg-light {
  background-color: #f4f8fa !important;
}

.members .subtitle {
  color: #8d97ad;
  line-height: 24px;
}

.members .pro-pic {
  min-height: 200px;
}

.members .desc {
  font-size: 0.8em;
}
.position {
  color: #8d97ad;
  font-size: 0.9em;
}

.pablo {
  background: url(/static/media/pablo.a53b9db0.png) no-repeat;
  background-size: cover;
}

.king {
  background: url(/static/media/king.71545788.png) no-repeat;
  background-size: cover;
}

.finezze {
  background: url(/static/media/finezze.fe0df498.png) no-repeat;
  background-size: cover;
}

.bee {
  background: url(/static/media/bee.4fa32aef.png) no-repeat;
  background-size: cover;
}

.custom-shape-divider-bottom-1641775128 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-bottom-1641775128 svg {
  position: relative;
  display: block;
  width: calc(146% + 1.3px);
  height: 88px;
}

.custom-shape-divider-bottom-1641775128 .shape-fill {
  fill: #373c3e;
}

